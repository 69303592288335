<template lang="pug">
Popup
    template(slot="title")
      template(v-if="!isEdit") {{ $t('Group.itemGroupFormHeaderTitle') }}
      template(v-else) {{ $t('Group.itemGroupFormHeaderTitleEdit') }}
    template(slot="content")
      form.Form(
        id              ="form-item-group",
        @submit.prevent ="submitFormItemGroup(isEdit)"
      )

        div.Form-item(v-if="!isEdit")
          .parent-group-info
            .row
              .col.title {{ $t('Group.itemGroupFormParentInfoLabel') }}
              .col.value
                span.parent-group-info__name
                  | {{ majorName }}

        div.Form-item.required
          label.Form-item-label {{ $t('Group.itemGroupFormFieldName') }}
          .control
            customTextInput(
              name="name"
              ref="formItemGroupFirstInput"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('Group.itemGroupFormFieldName')"
              v-validate="validateRules"
              id="input-group-index-form-item-name"
              v-model="form.itemGroup.name"
              :isDelayInput="true"
            )

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        div.Form-item.required(v-if="isEdit")
          label.Form-item-label {{ $t('Group.itemGroupFormFieldMajorGroup') }}

          .control.control.control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="hasMajorGroups",
              :optionData="majorGroupList"
              v-model="form.itemGroup.parentId",
              v-validate="'required|is_not:-1'"
              :data-vv-as ="$t('Group.itemGroupFormFieldMajorGroup')"
              :error="veeErrors.has('hasMajorGroups')"
              id="input-group-index-form-item-hasMajorGroups"
              optionIdName="option-group-hasMajorGroups"
            )

            showValidateError(
              v-show="veeErrors.has('hasMajorGroups')"
              :errorName="veeErrors.first('hasMajorGroups')"
            )

        div.Form-item
          label.Form-item-label {{ $t('Group.itemGroupFormFieldGroupCode') }}

          .control
            customTextInput(
              name="groupCode"
              id="input-group-index-form-item-group-code"
              :error="veeErrors.has('groupCode')"
              :data-vv-as="$t('Group.itemGroupFormFieldGroupCode')"
              v-model="form.itemGroup.groupCode"
            )

            showValidateError(
              v-show="veeErrors.has('groupCode')"
              :errorName="veeErrors.first('groupCode')"
            )
        div.flex
          div.Form-item.required.m-top-25
            label.Form-item-label {{ $t('Group.itemGroupFormFieldTax') }}

            .control.control.control.form-item-select
              Icon(name="icon-down-arrow")

              customSelectInput(
                name="groupTaxId",
                :optionData="Taxes"
                v-model="form.itemGroup.taxId",
                v-validate="'required|is_not:-1'"
                :data-vv-as ="$t('Group.itemGroupFormFieldTax')"
                :error="veeErrors.has('groupTaxId')"
                id="input-group-index-form-item-taxid"
                optionIdName="option-group-tax"
              )

              showValidateError(
                v-show="veeErrors.has('groupTaxId')"
                :errorName="veeErrors.first('groupTaxId')"
              )

          div.Form-item.m-top-25.m-left-10
            label.Form-item-label {{ $t('Group.salesTax') }}
            .control.control.control.form-item-select
              Icon(name="icon-down-arrow")

              customSelectInput(
                name="groupSalesTaxId"
                :optionData="Taxes"
                v-model="form.itemGroup.salesTaxId"
                :data-vv-as ="$t('Group.salesTax')"
                :error="veeErrors.has('groupSalesTaxId')"
                id="input-group-index-form-item-sales-taxId"
                optionIdName="option-group-sales-tax"
              )

              showValidateError(
                v-show="veeErrors.has('groupSalesTaxId')"
                :errorName="veeErrors.first('groupSalesTaxId')"
              )

        div.Form-item.m-top-25
          label.Form-item-label {{ $t('Global.approvalLevel') }}

          customSelect(
            componentName="item-group-approval-level"
            v-model="form.itemGroup.approvalLevel"
            :optionData="approvalLevels"
            :isDefaultTextActive="false"
          )

        div.m-top-25
          .m-bottom-10 {{$t('Stocks.popup_getFromDeviationSettings')}}
          .price-deviation
            .Form-item
              .control
                label.Form-item-label {{ $t('Stocks.popup_minPriceDeviation') }}
                customNumberInput.txt-right-icon(
                  name="minPriceDeviation"
                  id="item-group-input-min-price-deviation"
                  placeholder=",00"
                  :disabled="checkPermission('ItemGroupPriceDeviation') === false"
                  :isTypePercentage="true"
                  :error="veeErrors.has('minPriceDeviation')"
                  :data-vv-as="$t('Stocks.popup_minPriceDeviation')"
                  v-model="form.itemGroup.minPriceDeviation"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('minPriceDeviation')"
                  :errorName="veeErrors.first('minPriceDeviation')"
                )
            .Form-item.m-left-10
              .control
                label.Form-item-label {{ $t('Stocks.popup_maxPriceDeviation') }}
                customNumberInput.txt-right-icon(
                  name="maxPriceDeviation"
                  id="item-group-input-max-price-deviation"
                  placeholder=",00"
                  :disabled="checkPermission('ItemGroupPriceDeviation') === false"
                  :isTypePercentage="true"
                  :error="veeErrors.has('maxPriceDeviation')"
                  :data-vv-as="$t('Stocks.popup_maxPriceDeviation')"
                  v-model="form.itemGroup.maxPriceDeviation"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('maxPriceDeviation')"
                  :errorName="veeErrors.first('maxPriceDeviation')"
                )
            .Form-item
              .control
                label.Form-item-label {{ $t('Stocks.popup_minQuantityDeviation') }}
                customNumberInput.txt-right-icon(
                  name="minQuantityDeviation"
                  id="item-group-input-min-quantity-deviation"
                  :isTypePercentage="true"
                  placeholder=",00"
                  :disabled="checkPermission('ItemGroupQuantityDeviation') === false"
                  :error="veeErrors.has('minQuantityDeviation')"
                  :data-vv-as="$t('Stocks.popup_minQuantityDeviation')"
                  v-model="form.itemGroup.minQuantityDeviation"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('minQuantityDeviation')"
                  :errorName="veeErrors.first('minQuantityDeviation')"
                )
            .Form-item.m-left-10
              .control
                label.Form-item-label {{ $t('Stocks.popup_maxQuantityDeviation') }}
                customNumberInput.txt-right-icon(
                  name="maxQuantityDeviation"
                  id="item-group-input-max-quantity-deviation"
                  :isTypePercentage="true"
                  placeholder=",00"
                  :disabled="checkPermission('ItemGroupQuantityDeviation') === false"
                  :error="veeErrors.has('maxQuantityDeviation')"
                  :data-vv-as="$t('Stocks.popup_maxQuantityDeviation')"
                  v-model="form.itemGroup.maxQuantityDeviation"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('maxQuantityDeviation')"
                  :errorName="veeErrors.first('maxQuantityDeviation')"
                )
        div.m-top-25
          .m-bottom-10.m-top-25 {{$t('Stocks.popup_getWithoutDeviationSettings')}}
          .price-deviation
            .Form-item
              .control
                label.Form-item-label {{ $t('Stocks.popup_minPriceDeviation') }}
                customNumberInput.txt-right-icon(
                  name="minPriceDeviationWithoutOrder"
                  id="item-group-input-min-price-devition-without-order"
                  placeholder=",00"
                  :disabled="checkPermission('ItemGroupPriceDeviationWithoutOrder') === false"
                  :isTypePercentage="true"
                  :error="veeErrors.has('minPriceDeviationWithoutOrder')"
                  :data-vv-as="$t('Stocks.popup_minPriceDeviation')"
                  v-model="form.itemGroup.minPriceDeviationWithoutOrder"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('minPriceDeviationWithoutOrder')"
                  :errorName="veeErrors.first('minPriceDeviationWithoutOrder')"
                )
            .Form-item.m-left-10
              .control
                label.Form-item-label {{ $t('Stocks.popup_maxPriceDeviation') }}
                customNumberInput.txt-right-icon(
                  name="maxPriceDeviationWithoutOrder"
                  id="item-group-input-max-price-devition-without-order"
                  placeholder=",00"
                  :isTypePercentage="true"
                  :disabled="checkPermission('ItemGroupPriceDeviationWithoutOrder') === false"
                  :error="veeErrors.has('maxPriceDeviationWithoutOrder')"
                  :data-vv-as="$t('Stocks.popup_maxPriceDeviation')"
                  v-model="form.itemGroup.maxPriceDeviationWithoutOrder"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('maxPriceDeviationWithoutOrder')"
                  :errorName="veeErrors.first('maxPriceDeviationWithoutOrder')"
                )

    template(slot="footer")
          template(v-if="!isEdit")
            div.add-other-items-checkbox-container
              CustomCheckbox(
                id      ="checkbox-itemgroup-save-and-new"
                :label  ="$t('Global.formSaveAndNewText', { form: $t('Group.itemGroupForm_Name') })"
                v-model ="saveAndNew"
              )
            Button(
              id          ="btn-group-index-form-item-submit",
              primary,
              variant     ="full",
              type        ="submit",
              form        ="form-item-group",
              :disabled   ="isLoading",
              :isLoading  ="isLoading"
            )
              span(
                v-show    ="!isLoading"
              ) {{ $t('Group.itemGroupFormFooterSubmit') }}

          template(v-else)
            Button(
              id          ="btn-group-index-form-item-submit",
              primary,
              variant     ="full",
              form        ="form-item-group",
              type        ="submit",
              :disabled   ="isLoading",
              :isLoading  ="isLoading"
            )
              span(
                v-show    ="!isLoading"
              ) {{ $t('Group.itemGroupFormFooterSubmitEdit') }}

</template>
<script>
import customSelect from '@/view/global/custom-select'
import { mapGetters, mapActions } from 'vuex'
import { getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ItemGroupForm',
  components: {
    customSelect
  },
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      form: {
        itemGroup: {
          parentId: null,
          name: null,
          groupCode: null,
          taxId: null,
          orderNumber: 0,
          approvalLevel: 0,
          salesTaxId: null,
          minPriceDeviation: null,
          maxPriceDeviation: null,
          minQuantityDeviation: null,
          maxQuantityDeviation: null,
          minPriceDeviationWithoutOrder: null,
          maxPriceDeviationWithoutOrder: null
        }
      },
      saveAndNew: true,
      majorGroupList: [],
      approvalLevels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    }
  },
  computed: {
    ...mapGetters('Tax', ['Taxes']),

    ...mapGetters('Settings', ['checkPermission']),

    isEdit () {
      return this.status === 'edit'
    },
    majorName () {
      return this.$route.params.parentName ? this.$route.params.parentName : ''
    },
    validateRules () {
      const url = this.isEdit ? `Group/itemgroup/check/name?id=${this.$route.params.id},name` : `Group/itemgroup/check/name,name`
      return `required|max:32|very_singularity:${url}`
    },
    isLoading () {
      return this.$wait.is(['Group/createGroupItem', 'Group/updateGroupItem', 'submitFormItemGroup'])
    }
  },
  mounted () {
    this.form.itemGroup.parentId = this.$route.params.parentId
    if (this.isEdit) {
      this.getItemGroup({id: this.$route.params.id}).then(res => {
        if (!res) return
        this.form.itemGroup = {...res.data.itemGroup}
      }
      )
    }
    this.majorGroupListItems()
    this.focusFirstInput()
  },
  methods: {
    ...mapActions('Group', ['createGroupItem', 'updateGroupItem', 'getItemGroup', 'getGroupTree']),
    ...mapActions('Notify', ['notifyShow']),

    submitFormItemGroup: vueWaitLoader(async function (isEdit) {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        return isEdit ? this.submitFormItemGroupEdit() : this.submitFormItemGroupNew()
      })
    }, 'submitFormItemGroup'),

    resetForm () {
      this.form = {
        itemGroup: {
          parentId: this.form.itemGroup.parentId,
          name: null,
          groupCode: null,
          taxId: null,
          orderNumber: 0,
          approvalLevel: 0,
          salesTaxId: null,
          minPriceDeviation: null,
          maxPriceDeviation: null,
          minQuantityDeviation: null,
          maxQuantityDeviation: null,
          minPriceDeviationWithoutOrder: null,
          maxPriceDeviationWithoutOrder: null
        }
      }
    },
    majorGroupListItems () {
      if (this.$route.params.majorGroups) {
        this.majorGroupList = this.$route.params.majorGroups
      } else {
        this.getGroupTree(this.$route.params.overGroupId)
          .then(res => {
            this.majorGroupList = res.data.overGroupList[0].majorGroupList
          })
      }
    },
    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    async submitFormItemGroupEdit () {
      const payload = getObjectValuesByKey(this.form.itemGroup, ['id', 'parentId', 'name', 'groupCode', 'taxId', 'orderNumber', 'approvalLevel', 'salesTaxId', 'minPriceDeviation', 'maxPriceDeviation', 'minQuantityDeviation', 'maxQuantityDeviation', 'minPriceDeviationWithoutOrder', 'maxPriceDeviationWithoutOrder'])
      await this.updateGroupItem(payload)
        .then(res => {
          if (res) {
            res = res.data.itemGroup
            const message = this.$t('Global.notification_Updated', { name: res.name })
            this.notifyShow({ message })
            this.$emit('getMajorGroupList')
            this.close()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    focusFirstInput () {
      this.$refs.formItemGroupFirstInput.focusCustomInput()
    },
    async submitFormItemGroupNew () {
      await this.createGroupItem(this.form.itemGroup)
        .then(res => {
          if (res) {
            res = res.data.itemGroup
            this.$emit('getMajorGroupList')
            if (this.saveAndNew) {
              this.resetForm()
              this.focusFirstInput()
              this.$nextTick(() => {
                this.$validator.reset()
              })
            } else {
              this.close()
            }
            const message = this.$t('Global.notification_Created', { name: res.name })
            this.notifyShow({ message })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.parent-group-info {
    margin-bottom: 25px;

    .row {
      position: relative;
      margin-top: -1px;
      height: $finger-size;
      border: 1px solid $color-gray;
      background-color: white;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: $font-size-small;
      padding-right: 10px;
      justify-content: space-between;

      &.multiple-values {
        height: auto;
        min-height: $finger-size;
        align-items: flex-start;
        padding: 18px 10px;
      }

      &:first-child {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      .col {
        &.title {
          font-weight: $font-weight-bold;
          color: $color-success;
        }

        &.value {
          color: $color-text-dark;
        }

        .item-info__name {
          &:not(:last-child) {
            &::after {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              margin: 0 5px;
              vertical-align: middle;
              background: $color-gray;
              border-radius: 50%;
            }
          }
        }

      }

      &.multiple-values .col.value {
        padding-left: 10px;
        text-align: right;
      }
    }
  }
  .flex{
    display: flex;
    .Form-item{
      flex: 50%;
    }
  }

  :deep() .select-dropdown-custom-selected {
    height: 54px !important;
  }

  .price-deviation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .Form-item {
      flex: 45%;
      margin-top: 10px;
    }
  }

  :deep() .txt-right-icon {
    padding-right: unset !important;
  }

</style>
