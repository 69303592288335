var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          !_vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Group.overGroupFormHeaderTitle")))]
            : [_vm._v(_vm._s(_vm.$t("Group.overGroupFormHeaderTitleEdit")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-over-group" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitFormOverGroup(_vm.isEdit)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Group.overGroupFormFieldName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.validateRules,
                        expression: "validateRules",
                      },
                    ],
                    ref: "formOverGroupFirstInput",
                    attrs: {
                      name: "name",
                      id: "input-group-index-name",
                      error: _vm.veeErrors.has("name"),
                      "data-vv-as": _vm.$t("Group.overGroupFormFieldName"),
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.overGroup.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.overGroup, "name", $$v)
                      },
                      expression: "form.overGroup.name",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c(
                "label",
                { staticClass: "Form-item-label after-line m-bottom-15" },
                [_vm._v(_vm._s(_vm.$t("Group.overGroupIconName")))]
              ),
              _c(
                "div",
                { staticClass: "control icon-set" },
                _vm._l(_vm.selectedIconData, function (data) {
                  return _c("Button", {
                    key: data.key,
                    class: [
                      { "btn-border-blue bgc-light-primary": data.selected },
                    ],
                    attrs: {
                      id: "btn-group-index-new",
                      variant: "btn-border-gray",
                      iconName: data.text,
                      iconClass: data.text,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectIcon(data.selected ? null : data.id)
                      },
                    },
                  })
                }),
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? [
                _c(
                  "div",
                  { staticClass: "add-other-items-checkbox-container" },
                  [
                    _c("CustomCheckbox", {
                      attrs: {
                        id: "checkbox-overgroup-save-and-new",
                        label: _vm.$t("Global.formSaveAndNewText", {
                          form: _vm.$t("Group.overGroupForm_Name"),
                        }),
                      },
                      model: {
                        value: _vm.saveAndNew,
                        callback: function ($$v) {
                          _vm.saveAndNew = $$v
                        },
                        expression: "saveAndNew",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    attrs: {
                      success: "",
                      variant: "full",
                      id: "btn-group-index-submit",
                      form: "form-over-group",
                      type: "submit",
                      disabled: _vm.isLoading,
                      isLoading: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Group.overGroupFormFooterSubmit"))
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            : [
                _c(
                  "Button",
                  {
                    attrs: {
                      success: "",
                      variant: "full",
                      id: "btn-group-index-submit",
                      type: "submit",
                      form: "form-over-group",
                      disabled: _vm.isLoading,
                      isLoading: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Group.overGroupFormFooterSubmitEdit"))
                        ),
                      ]
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }