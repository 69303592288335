<template lang="pug">
.major-group
  .major-group-header
    .title {{ $t('Group.majorGroup_title') }}
    Button.btn-link.bgc-none(
      v-if="!isEmpty"
      size="medium"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      id="switch-btn-create-training-recipe"
      @click.stop="openPopUp('Major', 'New', overGroup)"
    )
      span {{ $t('Group.title_Side_Add_Button_Text') }}
  .major-list
    EmptyState(
      v-if="isEmpty",
      :title="$t('Group.emptyMajorGroupState_Title')",
      :description="$t('Group.emptyMajorGroupState_Description')"
      :buttonText="$t('Group.group_popup_FinishCreateButton')",
      @emptyAddButton="openPopUp('Major', 'New', overGroup)")
    .major-item.m-bottom-20(v-for="(node, index) in data")
      .item-header(:id="`major-group-item-header-${index}`")
        span {{ node.name }}
        .action
          ActionButton(
            :data="node.itemGroupList.length > 0 ? ['edit'] : ['edit', 'remove']"
            :item="node"
            @onItemEdit="openPopUp('Major', 'Edit', node)"
            @onItemDelete="openRemove('Major', node, node.parentId)"
          )
          Button(
          :justIcon="true"
          variant="btn-light-blue"
          id="btn-table-action-add"
          type="button"
          iconName="icon-action-add"
          iconClass="icon-action-add"
          @click.stop="openPopUp('Item', 'New', node)")
      .item-list(:id="`major-group-item-list-${index}`")
        TitleBox.item-node(
          v-for="(item, index) in node.itemGroupList"
          :key="index"
          :data="item"
          :title="item.name"
          :showTooltip="true"
          :tooltipCharacterSize = "20"
          :boxIndex="index"
          componentName="majorGroup"
          :actions="['edit', 'remove']"
          @delegateOnItemEdit="openPopUp('Item', 'Edit', $event)"
          @delegateOnItemRemove="openRemove('Item', item, item.parentId)"
        )
</template>

<script>
import TitleBox from '@/view/global/title-box'
export default {
  name: 'MajorGroup',
  components: {
    TitleBox
  },
  props: {
    data: {
      type: Array,
      default: null
    },
    overGroup: {
      type: Object,
      default: null
    },
    overGroups: {
      type: Array,
      default: null
    }
  },
  computed: {
    isEmpty () {
      return this.data.length === 0
    }
  },
  methods: {
    openRemove (groupName, removeItem, parentId) {
      this.$router.push({
        name: groupName + 'GroupRemove',
        params: {
          removeItem,
          parentGroup: parentId === this.overGroup.id ? this.overGroup : this.data.find(item => item.id === parentId),
          id: removeItem.id
        }
      })
    },
    openPopUp (groupName, action, data) {
      this.$router.push({
        name: action + groupName + 'GroupForm',
        params: {
          parentName: data.name,
          parentId: data.id,
          id: data.id,
          majorGroups: this.data,
          overGroups: this.overGroups,
          overGroupId: this.overGroup.id
        }
      })
    },
    shouldShow (item, node) {
      return ((item === 'remove' && node.itemGroupList.length === 0))
    }
  }
}
</script>

<style lang="scss" scoped>

.major-group{
  padding: 25px 0 25px 25px;
  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray;
    padding-bottom: 5px;
    margin-bottom: 20px;
    & .title{
      font-size: 20px;
      font-weight: $font-weight-bold;
      color: $color-op-gray;
    }
    & button{
      display: flex;
      align-items: center;
      & span {
        margin-left: 5px;
      }
    }
  }
}
.major-list{
  height: calc(100vh - 170px);
  overflow: scroll;
}
.major-item{
  padding: 25px 25px 15px 25px;
  border-radius: 6px;
  border: solid 1px #e1e9ee;
  background-color: #ffffff;
  &:hover{
    .item-header .action{
      opacity: 1;
    }
  }
  & .item{
    &-header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      & span {
        font-size: $font-size-big;
        color: $color-op-gray;
        font-weight: $font-weight-bold;
      }
      & .action{
        display: flex;
        opacity: 0;
      }
    }
    &-list{
      display: flex;
      flex-wrap: wrap;
    }
    &-node{
      max-width: 240px;
      min-width: 200px;
      margin-right: 15px;
    }
  }
}
</style>
