<template lang="pug">
DeleteAlert(
  :item="overGroup",
  :fields="fields",
  :tableName="$t('Group.removeOverGroup_TableName')",
  loading="Group/removeGroupOver",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'OverGroupRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('Group.removeOverGroup_TableColumn_Name_Short'),
        title: this.$t('Group.removeOverGroup_TableColumn_Name')
      }],
      overGroup: {},
      GroupList: {}
    }
  },

  async mounted () {
    if (this.$route.params.overGroup) {
      this.overGroup = this.$route.params.overGroup
    } else {
      this.getOverGroup({id: this.$route.params.id})
        .then(res => {
          this.overGroup = res.data.overGroup
        })
    }
  },

  methods: {
    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Group', ['removeGroupOver', 'getOverGroup']),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.removeGroupOver({
        overGroup: this.overGroup
      }).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.overGroup.name })
          this.notifyShow({ message })
          this.$emit('getOverGroupList')
          this.close()
        }
      })
    }, 'groupsRemoveOver')

  }
}
</script>
