<template lang="pug">
Popup
    template(slot="title")
      template(v-if="!isEdit") {{ $t('Group.overGroupFormHeaderTitle') }}
      template(v-else) {{ $t('Group.overGroupFormHeaderTitleEdit') }}

    template(slot="content")
          form.Form(
            id              ="form-over-group",
            @submit.prevent ="submitFormOverGroup(isEdit)"
          )
            div.Form-item.required
              label.Form-item-label {{ $t('Group.overGroupFormFieldName') }}
              .control
                customTextInput(
                  name="name"
                  ref="formOverGroupFirstInput",
                  id="input-group-index-name"
                  :error="veeErrors.has('name')"
                  :data-vv-as="$t('Group.overGroupFormFieldName')"
                  v-model="form.overGroup.name"
                  v-validate="validateRules"
                  :isDelayInput="true"
                )

                showValidateError(
                  v-show="veeErrors.has('name')"
                  :errorName="veeErrors.first('name')"
                )
            div.Form-item
              label.Form-item-label.after-line.m-bottom-15 {{ $t('Group.overGroupIconName') }}
              .control.icon-set
                Button(
                  v-for="data in selectedIconData"
                  :key="data.key"
                  :class="[{'btn-border-blue bgc-light-primary': data.selected }]"
                  id        ="btn-group-index-new"
                  variant    ="btn-border-gray"
                  @click    ="selectIcon(data.selected ? null : data.id)"
                  :iconName  ="data.text",
                  :iconClass ="data.text",
                )
    template(slot="footer")

          template(v-if="!isEdit")
            div.add-other-items-checkbox-container
              CustomCheckbox(
                id      ="checkbox-overgroup-save-and-new"
                :label  ="$t('Global.formSaveAndNewText', { form: $t('Group.overGroupForm_Name') })"
                v-model ="saveAndNew"
              )

            Button(
              success
              variant   ="full",
              id        ="btn-group-index-submit",
              form      ="form-over-group"
              type      ="submit",
              :disabled ="isLoading"
              :isLoading="isLoading"
            )
              span(
                v-show  ="!isLoading"
              ) {{ $t('Group.overGroupFormFooterSubmit') }}

          template(v-else)
            Button(
              success
              variant   ="full"
              id        ="btn-group-index-submit",
              type      ="submit",
              form      ="form-over-group",
              :disabled ="isLoading"
              :isLoading="isLoading"
            )
              span(v-show ="!isLoading"
              ) {{ $t('Group.overGroupFormFooterSubmitEdit') }}

</template>

<script>
import IconData from '../constant/icon-data'
import { mapActions } from 'vuex'
import { getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'OverGroupForm',


  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        overGroup: {
          id: null,
          name: null,
          orderNumber: null,
          iconName: null,
          majorGroupList: null
        }
      },
      selectedIconData: IconData.selectedIconData,
      saveAndNew: true
    }
  },

  mounted () {
    if (this.isEdit) {
      this.getOverGroup({id: this.$route.params.id}).then(res => {
        if (!res) return
        this.form.overGroup = {...res.data.overGroup}
        const icon = this.selectedIconData.find(item => item.text === this.form.overGroup.iconName)
        this.selectIcon(icon ? icon.id : null)
      }
      )
    } else this.selectIcon(null)
    this.focusFirstInput()
  },

  computed: {
    isEdit () {
      return this.status === 'edit'
    },
    validateRules () {
      const url = this.isEdit ? `Group/overgroup/check/name?id=${this.$route.params.id},name` : `Group/overgroup/check/name,name`
      return `required|max:32|very_singularity:${url}`
    },
    isLoading () {
      return this.$wait.is([ 'groupsSubmitFormOverGroup', 'Group/updateGroupOver', 'Group/createGroupOver' ])
    }
  },

  methods: {
    ...mapActions('Group', ['getOverGroup', 'updateGroupOver', 'createGroupOver']),
    ...mapActions('Notify', ['notifyShow']),

    resetForm () {
      this.form = {
        overGroup: {
          id: null,
          name: null,
          orderNumber: null,
          iconName: null,
          majorGroupList: null
        }
      }
      this.selectIcon(null)
    },
    selectIcon (id) {
      if (this.selectedIconData.find(item => item.selected === true)) {
        this.selectedIconData.find(item => item.selected === true).selected = false
        this.form.overGroup.iconName = null
      }
      if (id !== null) {
        const findItem = this.selectedIconData.find(item => item.id === id)
        findItem.selected = true
        this.form.overGroup.iconName = findItem.text
      }
    },
    focusFirstInput () {
      this.$refs.formOverGroupFirstInput.focusCustomInput()
    },

    submitFormOverGroup: vueWaitLoader(async function (isEdit) {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        return isEdit ? this.submitFormOverGroupEdit() : this.submitFormOverGroupNew()
      })
    }, 'groupsSubmitFormOverGroup'),

    async submitFormOverGroupNew () {
      await this.createGroupOver({name: this.form.overGroup.name, iconName: this.form.overGroup.iconName})
        .then(res => {
          if (!res) return
          if (this.saveAndNew) {
            this.resetForm()
            this.focusFirstInput()
            this.$nextTick(() => {
              this.$validator.reset()
            })
          } else {
            this.close()
          }
          this.$emit('getOverGroupList')
          const message = this.$t('Global.notification_Created', { name: res.name })
          this.notifyShow({ message })
        })
    },
    async submitFormOverGroupEdit () {
      const payload = getObjectValuesByKey(this.form.overGroup, ['id', 'name', 'orderNumber', 'iconName'])
      await this.updateGroupOver(payload)
        .then(res => {
          if (res) {
            this.$emit('getOverGroupList')
            this.close()
            const message = this.$t('Global.notification_Updated', { name: res.name })
            this.notifyShow({ message })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .icon-set{
    display: flex;
    flex-wrap: wrap;
    & button{
      width: 52px;
      height: 52px;
      padding: 0px;
      padding-left: 8px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
</style>
