var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DeleteAlert", {
    attrs: {
      item: _vm.itemGroupWithTaxRatio,
      fields: _vm.fields,
      tableName: _vm.isItemGroup
        ? _vm.$t("Group.removeItemGroup_TableName")
        : _vm.$t("Group.removeMajorGroup_TableName"),
      loading: "Group/removeGroupItem",
    },
    on: { delegateOnAlertRemoveOk: _vm.itemRemove },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }