<template lang="pug">
Popup
    template(slot="title")
      template(v-if="!isEdit") {{ $t('Group.majorGroupFormHeaderTitle') }}
      template(v-else) {{ $t('Group.majorGroupFormHeaderTitleEdit') }}
    template(slot="content")
          form.Form(
            id              ="form-major-group",
            @submit.prevent ="submitFormMajorGroup(isEdit)"
          )
            div.Form-item(v-if="!isEdit")
              .parent-group-info
                .row
                  .col.title {{ $t('Group.majorGroupFormParentInfoLabel') }}
                  .col.value
                    span.parent-group-info__name
                      | {{ overName }}
            div.Form-item.required
              label.Form-item-label {{ $t('Group.majorGroupFormFieldName') }}
              .control
                customTextInput(
                  name="name"
                  ref="formMajorGroupFirstInput",
                  id="input-group-index-form-major-name"
                  :error="veeErrors.has('name')"
                  :data-vv-as="$t('Group.majorGroupFormFieldName')"
                  v-model="form.majorGroup.name"
                  v-validate="validateRules"
                  :isDelayInput="true"
                )

                showValidateError(
                  v-show="veeErrors.has('name')"
                  :errorName="veeErrors.first('name')"
                )

            div.Form-item.required(v-if="isEdit")
              label.Form-item-label {{ $t('Group.itemGroupFormFieldOverGroup') }}

              .control.control.control.form-item-select
                Icon(name="icon-down-arrow")

                customSelectInput(
                  name="hasOverGroups",
                  :optionData="overGroupList"
                  v-model="form.majorGroup.parentId"
                  :data-vv-as ="$t('Group.itemGroupFormFieldOverGroup')"
                  :error="veeErrors.has('hasOverGroups')"
                  v-validate="'required|is_not:-1'"
                  id="input-group-index-form-item-hasOverGroups"
                  optionIdName="option-group-hasOverGroups"
                )

                showValidateError(
                  v-show="veeErrors.has('hasOverGroups')"
                  :errorName="veeErrors.first('hasOverGroups')"
                )

    template(slot="footer")
      template(
        v-if="!isEdit"
      )
        div.add-other-items-checkbox-container
          CustomCheckbox(
            id      ="checkbox-group-majorgroup-save-and-new"
            :label  ="$t('Global.formSaveAndNewText', { form: $t('Group.majorGroupForm_Name') })"
            v-model ="saveAndNew"
          )
        Button(
          primary
          id        ="btn-group-index-form-major-submit",
          variant   ="btn-full",
          form      ="form-major-group",
          type      ="submit",
          :disabled ="isLoading",
          :isLoading ="isLoading"
        )
          span(
            v-show="!isLoading"
          ) {{ $t('Group.majorGroupFormFooterSubmit') }}

      template(v-else)
        Button(
          primary
          id        ="btn-group-index-form-major-submit",
          variant   ="btn-full",
          type      ="submit"
          form      ="form-major-group",
          :disabled ="isLoading",
          :isLoading ="isLoading"
        )
          span(
            v-show  ="!isLoading"
          ) {{ $t('Group.majorGroupFormFooterSubmitEdit') }}

</template>
<script>
import { mapActions } from 'vuex'
import { getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'MajorGroupForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      form: {
        majorGroup: {
          id: null,
          parentId: null,
          name: null,
          orderNumber: null,
          itemGroupList: null
        }
      },
      overGroupList: [],
      saveAndNew: true
    }
  },
  computed: {
    isEdit () {
      return this.status === 'edit'
    },
    overName () {
      return this.$route.params.parentName ? this.$route.params.parentName : ''
    },
    validateRules () {
      const url = this.isEdit ? `Group/majorgroup/check/name?id=${this.$route.params.id},name` : `Group/majorgroup/check/name,name`
      return `required|max:32|very_singularity:${url}`
    },

    isLoading () {
      return this.$wait.is(['submitMajorGroupForm', 'Group/updateGroupMajor', 'Group/createGroupMajor'])
    }
  },
  mounted () {
    if (this.isEdit) {
      this.getMajorGroup({id: this.$route.params.id}).then(res => {
        if (!res) return
        this.form.majorGroup = {...res.data.majorGroup}
        this.overGroupListItems()
      }
      )
    }
    this.focusFirstInput()
  },
  methods: {
    ...mapActions('Group', ['createGroupMajor', 'updateGroupMajor', 'getMajorGroup', 'getOverGroupAll']),
    ...mapActions('Notify', ['notifyShow']),

    submitFormMajorGroup: vueWaitLoader(async function (isEdit) {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        return isEdit ? this.submitFormMajorGroupEdit() : this.submitFormMajorGroupNew()
      })
    }, 'submitMajorGroupForm'),

    focusFirstInput () {
      this.$refs.formMajorGroupFirstInput.focusCustomInput()
    },

    resetForm () {
      this.form = {
        majorGroup: {
          id: null,
          parentId: null,
          name: null,
          orderNumber: null,
          itemGroupList: null
        }
      }
    },
    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    overGroupListItems () {
      if (this.$route.params.overGroups) {
        this.overGroupList = this.$route.params.overGroups
      } else {
        this.getOverGroupAll().then(res => {
          this.overGroupList = !res ? null : res.data.overGroupList
        })
      }
    },
    async submitFormMajorGroupEdit () {
      let payload = getObjectValuesByKey(this.form.majorGroup, ['id', 'name', 'orderNumber'])
      payload.overGroupId = this.form.majorGroup.parentId
      await this.updateGroupMajor(payload)
        .then(res => {
          if (res) {
            res = res.data.majorGroup
            const message = this.$t('Global.notification_Updated', { name: res.name })
            this.notifyShow({ message })
            this.$emit('getMajorGroupList')
            this.close()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async submitFormMajorGroupNew () {
      await this.createGroupMajor({overGroupId: this.$route.params.parentId, name: this.form.majorGroup.name})
        .then(res => {
          if (res) {
            res = res.data.majorGroup
            if (this.saveAndNew) {
              this.resetForm()
              this.focusFirstInput()
              this.$nextTick(() => {
                this.$validator.reset()
              })
            } else {
              this.close()
            }
            const message = this.$t('Global.notification_Created', { name: res.name })
            this.notifyShow({ message })
            this.$emit('getMajorGroupList')
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.parent-group-info {
    margin-bottom: 25px;

    .row {
      position: relative;
      margin-top: -1px;
      height: $finger-size;
      border: 1px solid $color-gray;
      background-color: white;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: $font-size-small;
      padding-right: 10px;
      justify-content: space-between;

      &.multiple-values {
        height: auto;
        min-height: $finger-size;
        align-items: flex-start;
        padding: 18px 10px;
      }

      &:first-child {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      .col {
        &.title {
          font-weight: $font-weight-bold;
          color: $color-success;
        }

        &.value {
          color: $color-text-dark;
        }

        .item-info__name {
          &:not(:last-child) {
            &::after {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              margin: 0 5px;
              vertical-align: middle;
              background: $color-gray;
              border-radius: 50%;
            }
          }
        }

      }

      &.multiple-values .col.value {
        padding-left: 10px;
        text-align: right;
      }
    }
  }
</style>
