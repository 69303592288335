var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "over-group-side" },
    [
      _c(
        "div",
        { staticClass: "over-group-header" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("Group.overGroup_title"))),
          ]),
          _c(
            "Button",
            {
              staticClass: "btn-link bgc-none",
              attrs: {
                size: "medium",
                id: "switch-btn-create-training-recipe",
                iconName: "icon-global-add",
                iconClass: "icon-global-add",
              },
              on: {
                click: function ($event) {
                  return _vm.openOverGroupAddPopup()
                },
              },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("Group.title_Side_Add_Button_Text"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._l(_vm.data, function (item, index) {
        return _c("TitleBox", {
          key: index,
          attrs: {
            data: item,
            boxIndex: index,
            componentName: "overGroup",
            active: _vm.isActive(item.id),
            title: item.name,
            showTooltip: true,
            tooltipCharacterSize: 15,
            icon: item.iconName || "simpra-logo-dark",
            actions: _vm.actions(item.hasMajorGroup),
          },
          on: {
            delegateOnItemEdit: function ($event) {
              return _vm.editOverGroupAddPopup($event)
            },
            delegateOnItemView: () => _vm.$emit("changeSelect", item),
            delegateOnItemRemove: function ($event) {
              return _vm.removeOverGroupPopup($event)
            },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }