var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "major-group" }, [
    _c(
      "div",
      { staticClass: "major-group-header" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("Group.majorGroup_title"))),
        ]),
        !_vm.isEmpty
          ? _c(
              "Button",
              {
                staticClass: "btn-link bgc-none",
                attrs: {
                  size: "medium",
                  iconName: "icon-global-add",
                  iconClass: "icon-global-add",
                  id: "switch-btn-create-training-recipe",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.openPopUp("Major", "New", _vm.overGroup)
                  },
                },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("Group.title_Side_Add_Button_Text"))),
                ]),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "major-list" },
      [
        _vm.isEmpty
          ? _c("EmptyState", {
              attrs: {
                title: _vm.$t("Group.emptyMajorGroupState_Title"),
                description: _vm.$t("Group.emptyMajorGroupState_Description"),
                buttonText: _vm.$t("Group.group_popup_FinishCreateButton"),
              },
              on: {
                emptyAddButton: function ($event) {
                  return _vm.openPopUp("Major", "New", _vm.overGroup)
                },
              },
            })
          : _vm._e(),
        _vm._l(_vm.data, function (node, index) {
          return _c("div", { staticClass: "major-item m-bottom-20" }, [
            _c(
              "div",
              {
                staticClass: "item-header",
                attrs: { id: `major-group-item-header-${index}` },
              },
              [
                _c("span", [_vm._v(_vm._s(node.name))]),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c("ActionButton", {
                      attrs: {
                        data:
                          node.itemGroupList.length > 0
                            ? ["edit"]
                            : ["edit", "remove"],
                        item: node,
                      },
                      on: {
                        onItemEdit: function ($event) {
                          return _vm.openPopUp("Major", "Edit", node)
                        },
                        onItemDelete: function ($event) {
                          return _vm.openRemove("Major", node, node.parentId)
                        },
                      },
                    }),
                    _c("Button", {
                      attrs: {
                        justIcon: true,
                        variant: "btn-light-blue",
                        id: "btn-table-action-add",
                        type: "button",
                        iconName: "icon-action-add",
                        iconClass: "icon-action-add",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openPopUp("Item", "New", node)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "item-list",
                attrs: { id: `major-group-item-list-${index}` },
              },
              _vm._l(node.itemGroupList, function (item, index) {
                return _c("TitleBox", {
                  key: index,
                  staticClass: "item-node",
                  attrs: {
                    data: item,
                    title: item.name,
                    showTooltip: true,
                    tooltipCharacterSize: 20,
                    boxIndex: index,
                    componentName: "majorGroup",
                    actions: ["edit", "remove"],
                  },
                  on: {
                    delegateOnItemEdit: function ($event) {
                      return _vm.openPopUp("Item", "Edit", $event)
                    },
                    delegateOnItemRemove: function ($event) {
                      return _vm.openRemove("Item", item, item.parentId)
                    },
                  },
                })
              }),
              1
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }