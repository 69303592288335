<template lang="pug">
DeleteAlert(
  :item="itemGroupWithTaxRatio",
  :fields="fields",
  :tableName="isItemGroup ? $t('Group.removeItemGroup_TableName') : $t('Group.removeMajorGroup_TableName')",
  loading="Group/removeGroupItem",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getObjectValue, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ItemGroupRemove',

  props: {
    status: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.status === 'Item' ? this.$t('Group.removeItemGroup_TableColumn_Name_Short') : this.$t('Group.removeMajorGroup_TableColumn_Name_Short'),
        title: this.status === 'Item' ? this.$t('Group.removeItemGroup_TableColumn_Name') : this.$t('Group.removeMajorGroup_TableColumn_Name')
      }, {
        name: 'groupCode',
        is_visible: this.status === 'Item',
        short_title: this.$t('Group.removeItemGroup_TableColumn_GroupCode_Short'),
        title: this.$t('Group.removeItemGroup_TableColumn_GroupCode')
      }, {
        name: '_taxRatio',
        is_visible: this.status === 'Item',
        short_title: this.$t('Group.removeItemGroup_TableColumn_TaxRatio_Short'),
        title: this.$t('Group.removeItemGroup_TableColumn_TaxRatio')
      }],
      removeItem: {},
      parentGroup: {}
    }
  },

  async mounted () {
    if (this.$route.params.removeItem) {
      this.removeItem = this.$route.params.removeItem
    } else {
      if (this.isItemGroup) {
        this.getItemGroup({id: this.$route.params.id}).then(res => {
          if (!res) return
          this.removeItem = res.data.itemGroup
        })
      } else {
        this.getMajorGroup({id: this.$route.params.id}).then(res => {
          if (!res) return
          this.removeItem = res.data.majorGroup
        })
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Group', [
      'removeGroupItem',
      'removeGroupMajor',
      'getMajorGroup',
      'getItemGroup'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const params = this.isItemGroup
        ? {itemGroup: this.removeItem}
        : {majorGroup: this.removeItem}
      const functionName = this.isItemGroup ? 'removeGroupItem' : 'removeGroupMajor'
      await this[functionName]({...params}).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.removeItem.name })
          this.notifyShow({ message })
          this.$emit('getMajorGroupList')
          this.close()
        }
      })
    }, 'groupsRemoveMajorItem')
  },

  computed: {
    ...mapGetters('Tax', [
      'findTaxItem'
    ]),

    isItemGroup () {
      return this.status === 'Item'
    },

    itemGroupWithTaxRatio () {
      const taxRatio = getObjectValue('ratio', this.findTaxItem(this.removeItem.taxId), 0)
      return this.isItemGroup ? Object.assign({}, this.removeItem, {
        _taxRatio: `${taxRatio}%`
      }) : Object.assign({}, this.removeItem)
    }
  }
}
</script>
