<template lang="pug">
.over-group-side
  .over-group-header
    .title {{ $t('Group.overGroup_title') }}
    Button.btn-link.bgc-none(
      size="medium"
      id="switch-btn-create-training-recipe"
      iconName="icon-global-add"
      iconClass="icon-global-add"
      @click="openOverGroupAddPopup()"
    )
      span {{ $t('Group.title_Side_Add_Button_Text') }}
  TitleBox(
    v-for="(item, index) in data"
    :key="index"
    :data="item"
    :boxIndex="index"
    componentName="overGroup"
    :active="isActive(item.id)"
    :title="item.name"
    :showTooltip="true"
    :tooltipCharacterSize = "15"
    :icon="item.iconName || 'simpra-logo-dark'"
    :actions="actions(item.hasMajorGroup)"
    @delegateOnItemEdit="editOverGroupAddPopup($event)"
    @delegateOnItemView="() => $emit('changeSelect', item)"
    @delegateOnItemRemove="removeOverGroupPopup($event)"
  )
</template>

<script>
import TitleBox from '@/view/global/title-box'
export default {
  name: 'OverGroup',
  components: {
    TitleBox
  },
  props: {
    data: {
      type: Array,
      default: null
    },
    overGroup: {
      type: Object,
      default: null
    }
  },
  methods: {
    actions (hasMajor) {
      const actions = ['edit', 'view']
      if (!hasMajor) actions.unshift('remove')
      return actions
    },
    isActive (id) {
      return this.overGroup ? this.overGroup.id === id : false
    },
    removeOverGroupPopup (event) {
      this.$router.push({
        name: 'RemoveOverGroupForm',
        params: {
          overGroup: event,
          id: event.id
        }
      })
    },
    openOverGroupAddPopup () {
      this.$router.push({
        name: 'NewOverGroupForm'
      })
    },
    editOverGroupAddPopup (event) {
      this.$router.push({
        name: 'EditOverGroupForm',
        params: {
          id: event.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep() .title-box{
    &-top{
      max-width: 170px !important;
      min-width: 150px !important;
    }
  }
.over-group{
  &-side{
    position: sticky;
    width: 350px;
    height: calc(100vh - 70px);
    box-shadow: 7px 8px 12px 0 rgba(0, 0, 0, 0.04);
    padding: 25px 25px 25px 0px;
    overflow: scroll;
  }
  &-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray;
    padding-bottom: 10px;
    margin-bottom: 20px;
    & .title{
      font-size: 20px;
      font-weight: $font-weight-bold;
      color: $color-op-gray;
    }
    & button{
      display: flex;
      align-items: center;
      & span {
        margin-left: 5px;
      }
    }
  }
}
</style>
