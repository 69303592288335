var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          !_vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Group.majorGroupFormHeaderTitle")))]
            : [_vm._v(_vm._s(_vm.$t("Group.majorGroupFormHeaderTitleEdit")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-major-group" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitFormMajorGroup(_vm.isEdit)
              },
            },
          },
          [
            !_vm.isEdit
              ? _c("div", { staticClass: "Form-item" }, [
                  _c("div", { staticClass: "parent-group-info" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Group.majorGroupFormParentInfoLabel"))
                        ),
                      ]),
                      _c("div", { staticClass: "col value" }, [
                        _c("span", { staticClass: "parent-group-info__name" }, [
                          _vm._v(_vm._s(_vm.overName)),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Group.majorGroupFormFieldName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.validateRules,
                        expression: "validateRules",
                      },
                    ],
                    ref: "formMajorGroupFirstInput",
                    attrs: {
                      name: "name",
                      id: "input-group-index-form-major-name",
                      error: _vm.veeErrors.has("name"),
                      "data-vv-as": _vm.$t("Group.majorGroupFormFieldName"),
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.majorGroup.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.majorGroup, "name", $$v)
                      },
                      expression: "form.majorGroup.name",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _vm.isEdit
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Group.itemGroupFormFieldOverGroup"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control control control form-item-select" },
                    [
                      _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                      _c("customSelectInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|is_not:-1",
                            expression: "'required|is_not:-1'",
                          },
                        ],
                        attrs: {
                          name: "hasOverGroups",
                          optionData: _vm.overGroupList,
                          "data-vv-as": _vm.$t(
                            "Group.itemGroupFormFieldOverGroup"
                          ),
                          error: _vm.veeErrors.has("hasOverGroups"),
                          id: "input-group-index-form-item-hasOverGroups",
                          optionIdName: "option-group-hasOverGroups",
                        },
                        model: {
                          value: _vm.form.majorGroup.parentId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.majorGroup, "parentId", $$v)
                          },
                          expression: "form.majorGroup.parentId",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("hasOverGroups"),
                            expression: "veeErrors.has('hasOverGroups')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("hasOverGroups"),
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? [
                _c(
                  "div",
                  { staticClass: "add-other-items-checkbox-container" },
                  [
                    _c("CustomCheckbox", {
                      attrs: {
                        id: "checkbox-group-majorgroup-save-and-new",
                        label: _vm.$t("Global.formSaveAndNewText", {
                          form: _vm.$t("Group.majorGroupForm_Name"),
                        }),
                      },
                      model: {
                        value: _vm.saveAndNew,
                        callback: function ($$v) {
                          _vm.saveAndNew = $$v
                        },
                        expression: "saveAndNew",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    attrs: {
                      primary: "",
                      id: "btn-group-index-form-major-submit",
                      variant: "btn-full",
                      form: "form-major-group",
                      type: "submit",
                      disabled: _vm.isLoading,
                      isLoading: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Group.majorGroupFormFooterSubmit"))
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            : [
                _c(
                  "Button",
                  {
                    attrs: {
                      primary: "",
                      id: "btn-group-index-form-major-submit",
                      variant: "btn-full",
                      type: "submit",
                      form: "form-major-group",
                      disabled: _vm.isLoading,
                      isLoading: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Group.majorGroupFormFooterSubmitEdit"))
                        ),
                      ]
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }