import Index from '../'
import OverGroupForm from '../popup/over-group-form'
import MajorGroupForm from '../popup/major-group-form'
import ItemGroupForm from '../popup/item-group-form'
import MajorItemGroupRemove from '../popup/remove-major-item'
import OverGroupRemove from '../popup/remove-over'

export default [
  {
    path: '/group',
    name: 'Group',
    component: Index,
    props: true,
    children: [
      {
        name: 'NewOverGroupForm',
        path: '/group/overgroup/new',
        component: OverGroupForm
      },
      {
        name: 'EditOverGroupForm',
        path: '/group/overgroup/edit/:id',
        component: OverGroupForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'NewMajorGroupForm',
        path: '/group/majorgroup/new/:parentName/:parentId',
        component: MajorGroupForm
      },
      {
        name: 'EditMajorGroupForm',
        path: '/group/majorgroup/edit/:parentName/:id',
        component: MajorGroupForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'NewItemGroupForm',
        path: '/group/itemgroup/new/:parentName/:parentId',
        component: ItemGroupForm
      },
      {
        name: 'EditItemGroupForm',
        path: '/group/itemgroup/edit/:overGroupId/:parentName/:id',
        component: ItemGroupForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'ItemGroupRemove',
        path: 'remove-item-group/:id',
        component: MajorItemGroupRemove,
        props: {
          status: 'Item'
        }
      },
      {
        name: 'MajorGroupRemove',
        path: 'remove-major-group/:id',
        component: MajorItemGroupRemove,
        props: {
          status: 'Major'
        }
      },
      {
        name: 'RemoveOverGroupForm',
        path: 'remove-over-group/:id',
        component: OverGroupRemove
      }
    ],
    meta: {
      main_menu: true,
      slug: 'group',
      category: 'Main_data',
      permissionKey: 'GroupsMenu',
      page: 'group'
    }
  }
]
