var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          !_vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Group.itemGroupFormHeaderTitle")))]
            : [_vm._v(_vm._s(_vm.$t("Group.itemGroupFormHeaderTitleEdit")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-item-group" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitFormItemGroup(_vm.isEdit)
              },
            },
          },
          [
            !_vm.isEdit
              ? _c("div", { staticClass: "Form-item" }, [
                  _c("div", { staticClass: "parent-group-info" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Group.itemGroupFormParentInfoLabel"))
                        ),
                      ]),
                      _c("div", { staticClass: "col value" }, [
                        _c("span", { staticClass: "parent-group-info__name" }, [
                          _vm._v(_vm._s(_vm.majorName)),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Group.itemGroupFormFieldName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.validateRules,
                        expression: "validateRules",
                      },
                    ],
                    ref: "formItemGroupFirstInput",
                    attrs: {
                      name: "name",
                      error: _vm.veeErrors.has("name"),
                      "data-vv-as": _vm.$t("Group.itemGroupFormFieldName"),
                      id: "input-group-index-form-item-name",
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.itemGroup.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.itemGroup, "name", $$v)
                      },
                      expression: "form.itemGroup.name",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _vm.isEdit
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("Group.itemGroupFormFieldMajorGroup"))
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control control control form-item-select" },
                    [
                      _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                      _c("customSelectInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|is_not:-1",
                            expression: "'required|is_not:-1'",
                          },
                        ],
                        attrs: {
                          name: "hasMajorGroups",
                          optionData: _vm.majorGroupList,
                          "data-vv-as": _vm.$t(
                            "Group.itemGroupFormFieldMajorGroup"
                          ),
                          error: _vm.veeErrors.has("hasMajorGroups"),
                          id: "input-group-index-form-item-hasMajorGroups",
                          optionIdName: "option-group-hasMajorGroups",
                        },
                        model: {
                          value: _vm.form.itemGroup.parentId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.itemGroup, "parentId", $$v)
                          },
                          expression: "form.itemGroup.parentId",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("hasMajorGroups"),
                            expression: "veeErrors.has('hasMajorGroups')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("hasMajorGroups"),
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Group.itemGroupFormFieldGroupCode"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    attrs: {
                      name: "groupCode",
                      id: "input-group-index-form-item-group-code",
                      error: _vm.veeErrors.has("groupCode"),
                      "data-vv-as": _vm.$t("Group.itemGroupFormFieldGroupCode"),
                    },
                    model: {
                      value: _vm.form.itemGroup.groupCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.itemGroup, "groupCode", $$v)
                      },
                      expression: "form.itemGroup.groupCode",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("groupCode"),
                        expression: "veeErrors.has('groupCode')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("groupCode") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "Form-item required m-top-25" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Group.itemGroupFormFieldTax"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control control control form-item-select" },
                  [
                    _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                    _c("customSelectInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|is_not:-1",
                          expression: "'required|is_not:-1'",
                        },
                      ],
                      attrs: {
                        name: "groupTaxId",
                        optionData: _vm.Taxes,
                        "data-vv-as": _vm.$t("Group.itemGroupFormFieldTax"),
                        error: _vm.veeErrors.has("groupTaxId"),
                        id: "input-group-index-form-item-taxid",
                        optionIdName: "option-group-tax",
                      },
                      model: {
                        value: _vm.form.itemGroup.taxId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.itemGroup, "taxId", $$v)
                        },
                        expression: "form.itemGroup.taxId",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("groupTaxId"),
                          expression: "veeErrors.has('groupTaxId')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("groupTaxId") },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "Form-item m-top-25 m-left-10" }, [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Group.salesTax"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control control control form-item-select" },
                  [
                    _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                    _c("customSelectInput", {
                      attrs: {
                        name: "groupSalesTaxId",
                        optionData: _vm.Taxes,
                        "data-vv-as": _vm.$t("Group.salesTax"),
                        error: _vm.veeErrors.has("groupSalesTaxId"),
                        id: "input-group-index-form-item-sales-taxId",
                        optionIdName: "option-group-sales-tax",
                      },
                      model: {
                        value: _vm.form.itemGroup.salesTaxId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.itemGroup, "salesTaxId", $$v)
                        },
                        expression: "form.itemGroup.salesTaxId",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("groupSalesTaxId"),
                          expression: "veeErrors.has('groupSalesTaxId')",
                        },
                      ],
                      attrs: {
                        errorName: _vm.veeErrors.first("groupSalesTaxId"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "Form-item m-top-25" },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("Global.approvalLevel"))),
                ]),
                _c("customSelect", {
                  attrs: {
                    componentName: "item-group-approval-level",
                    optionData: _vm.approvalLevels,
                    isDefaultTextActive: false,
                  },
                  model: {
                    value: _vm.form.itemGroup.approvalLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.itemGroup, "approvalLevel", $$v)
                    },
                    expression: "form.itemGroup.approvalLevel",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "m-top-25" }, [
              _c("div", { staticClass: "m-bottom-10" }, [
                _vm._v(_vm._s(_vm.$t("Stocks.popup_getFromDeviationSettings"))),
              ]),
              _c("div", { staticClass: "price-deviation" }, [
                _c("div", { staticClass: "Form-item" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Stocks.popup_minPriceDeviation"))
                        ),
                      ]),
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "greater_than:-1|max_value:100",
                            expression: "'greater_than:-1|max_value:100'",
                          },
                        ],
                        staticClass: "txt-right-icon",
                        attrs: {
                          name: "minPriceDeviation",
                          id: "item-group-input-min-price-deviation",
                          placeholder: ",00",
                          disabled:
                            _vm.checkPermission("ItemGroupPriceDeviation") ===
                            false,
                          isTypePercentage: true,
                          error: _vm.veeErrors.has("minPriceDeviation"),
                          "data-vv-as": _vm.$t(
                            "Stocks.popup_minPriceDeviation"
                          ),
                        },
                        model: {
                          value: _vm.form.itemGroup.minPriceDeviation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.itemGroup,
                              "minPriceDeviation",
                              $$v
                            )
                          },
                          expression: "form.itemGroup.minPriceDeviation",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("minPriceDeviation"),
                            expression: "veeErrors.has('minPriceDeviation')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("minPriceDeviation"),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "Form-item m-left-10" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Stocks.popup_maxPriceDeviation"))
                        ),
                      ]),
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "greater_than:-1|max_value:100",
                            expression: "'greater_than:-1|max_value:100'",
                          },
                        ],
                        staticClass: "txt-right-icon",
                        attrs: {
                          name: "maxPriceDeviation",
                          id: "item-group-input-max-price-deviation",
                          placeholder: ",00",
                          disabled:
                            _vm.checkPermission("ItemGroupPriceDeviation") ===
                            false,
                          isTypePercentage: true,
                          error: _vm.veeErrors.has("maxPriceDeviation"),
                          "data-vv-as": _vm.$t(
                            "Stocks.popup_maxPriceDeviation"
                          ),
                        },
                        model: {
                          value: _vm.form.itemGroup.maxPriceDeviation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.itemGroup,
                              "maxPriceDeviation",
                              $$v
                            )
                          },
                          expression: "form.itemGroup.maxPriceDeviation",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("maxPriceDeviation"),
                            expression: "veeErrors.has('maxPriceDeviation')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("maxPriceDeviation"),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "Form-item" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Stocks.popup_minQuantityDeviation"))
                        ),
                      ]),
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "greater_than:-1|max_value:100",
                            expression: "'greater_than:-1|max_value:100'",
                          },
                        ],
                        staticClass: "txt-right-icon",
                        attrs: {
                          name: "minQuantityDeviation",
                          id: "item-group-input-min-quantity-deviation",
                          isTypePercentage: true,
                          placeholder: ",00",
                          disabled:
                            _vm.checkPermission(
                              "ItemGroupQuantityDeviation"
                            ) === false,
                          error: _vm.veeErrors.has("minQuantityDeviation"),
                          "data-vv-as": _vm.$t(
                            "Stocks.popup_minQuantityDeviation"
                          ),
                        },
                        model: {
                          value: _vm.form.itemGroup.minQuantityDeviation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.itemGroup,
                              "minQuantityDeviation",
                              $$v
                            )
                          },
                          expression: "form.itemGroup.minQuantityDeviation",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("minQuantityDeviation"),
                            expression: "veeErrors.has('minQuantityDeviation')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first(
                            "minQuantityDeviation"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "Form-item m-left-10" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Stocks.popup_maxQuantityDeviation"))
                        ),
                      ]),
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "greater_than:-1|max_value:100",
                            expression: "'greater_than:-1|max_value:100'",
                          },
                        ],
                        staticClass: "txt-right-icon",
                        attrs: {
                          name: "maxQuantityDeviation",
                          id: "item-group-input-max-quantity-deviation",
                          isTypePercentage: true,
                          placeholder: ",00",
                          disabled:
                            _vm.checkPermission(
                              "ItemGroupQuantityDeviation"
                            ) === false,
                          error: _vm.veeErrors.has("maxQuantityDeviation"),
                          "data-vv-as": _vm.$t(
                            "Stocks.popup_maxQuantityDeviation"
                          ),
                        },
                        model: {
                          value: _vm.form.itemGroup.maxQuantityDeviation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.itemGroup,
                              "maxQuantityDeviation",
                              $$v
                            )
                          },
                          expression: "form.itemGroup.maxQuantityDeviation",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("maxQuantityDeviation"),
                            expression: "veeErrors.has('maxQuantityDeviation')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first(
                            "maxQuantityDeviation"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "m-top-25" }, [
              _c("div", { staticClass: "m-bottom-10 m-top-25" }, [
                _vm._v(
                  _vm._s(_vm.$t("Stocks.popup_getWithoutDeviationSettings"))
                ),
              ]),
              _c("div", { staticClass: "price-deviation" }, [
                _c("div", { staticClass: "Form-item" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Stocks.popup_minPriceDeviation"))
                        ),
                      ]),
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "greater_than:-1|max_value:100",
                            expression: "'greater_than:-1|max_value:100'",
                          },
                        ],
                        staticClass: "txt-right-icon",
                        attrs: {
                          name: "minPriceDeviationWithoutOrder",
                          id: "item-group-input-min-price-devition-without-order",
                          placeholder: ",00",
                          disabled:
                            _vm.checkPermission(
                              "ItemGroupPriceDeviationWithoutOrder"
                            ) === false,
                          isTypePercentage: true,
                          error: _vm.veeErrors.has(
                            "minPriceDeviationWithoutOrder"
                          ),
                          "data-vv-as": _vm.$t(
                            "Stocks.popup_minPriceDeviation"
                          ),
                        },
                        model: {
                          value:
                            _vm.form.itemGroup.minPriceDeviationWithoutOrder,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.itemGroup,
                              "minPriceDeviationWithoutOrder",
                              $$v
                            )
                          },
                          expression:
                            "form.itemGroup.minPriceDeviationWithoutOrder",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has(
                              "minPriceDeviationWithoutOrder"
                            ),
                            expression:
                              "veeErrors.has('minPriceDeviationWithoutOrder')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first(
                            "minPriceDeviationWithoutOrder"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "Form-item m-left-10" }, [
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Stocks.popup_maxPriceDeviation"))
                        ),
                      ]),
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "greater_than:-1|max_value:100",
                            expression: "'greater_than:-1|max_value:100'",
                          },
                        ],
                        staticClass: "txt-right-icon",
                        attrs: {
                          name: "maxPriceDeviationWithoutOrder",
                          id: "item-group-input-max-price-devition-without-order",
                          placeholder: ",00",
                          isTypePercentage: true,
                          disabled:
                            _vm.checkPermission(
                              "ItemGroupPriceDeviationWithoutOrder"
                            ) === false,
                          error: _vm.veeErrors.has(
                            "maxPriceDeviationWithoutOrder"
                          ),
                          "data-vv-as": _vm.$t(
                            "Stocks.popup_maxPriceDeviation"
                          ),
                        },
                        model: {
                          value:
                            _vm.form.itemGroup.maxPriceDeviationWithoutOrder,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.itemGroup,
                              "maxPriceDeviationWithoutOrder",
                              $$v
                            )
                          },
                          expression:
                            "form.itemGroup.maxPriceDeviationWithoutOrder",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has(
                              "maxPriceDeviationWithoutOrder"
                            ),
                            expression:
                              "veeErrors.has('maxPriceDeviationWithoutOrder')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first(
                            "maxPriceDeviationWithoutOrder"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? [
                _c(
                  "div",
                  { staticClass: "add-other-items-checkbox-container" },
                  [
                    _c("CustomCheckbox", {
                      attrs: {
                        id: "checkbox-itemgroup-save-and-new",
                        label: _vm.$t("Global.formSaveAndNewText", {
                          form: _vm.$t("Group.itemGroupForm_Name"),
                        }),
                      },
                      model: {
                        value: _vm.saveAndNew,
                        callback: function ($$v) {
                          _vm.saveAndNew = $$v
                        },
                        expression: "saveAndNew",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    attrs: {
                      id: "btn-group-index-form-item-submit",
                      primary: "",
                      variant: "full",
                      type: "submit",
                      form: "form-item-group",
                      disabled: _vm.isLoading,
                      isLoading: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Group.itemGroupFormFooterSubmit"))
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            : [
                _c(
                  "Button",
                  {
                    attrs: {
                      id: "btn-group-index-form-item-submit",
                      primary: "",
                      variant: "full",
                      form: "form-item-group",
                      type: "submit",
                      disabled: _vm.isLoading,
                      isLoading: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Group.itemGroupFormFooterSubmitEdit"))
                        ),
                      ]
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }