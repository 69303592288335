export default {
  selectedIconData: [
    {
      id: 1,
      text: 'icon-restaurant-bell',
      selected: false
    },
    {
      id: 2,
      text: 'icon-drink-bottle',
      selected: false
    },
    {
      id: 3,
      text: 'icon-spray-bottle',
      selected: false
    },
    {
      id: 4,
      text: 'icon-chair-barber',
      selected: false
    },
    {
      id: 5,
      text: 'icon-trolley-box',
      selected: false
    },
    {
      id: 6,
      text: 'icon-file-edit',
      selected: false
    },
    {
      id: 7,
      text: 'icon-oil-jerry-can',
      selected: false
    },
    {
      id: 8,
      text: 'icon-file-copy',
      selected: false
    }
  ]
}
