import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,

  state: {
    groups: [],
    allItemGroups: [],
    allOverGroups: []
  },

  getters: {
    allItemGroups: state => state.allItemGroups,
    allOverGroups: state => state.allOverGroups.overGroupList || []
  },

  actions: {
    async getGroupTree({ commit, dispatch }, id) {
      const results = await requestHandler({
        title: 'Group Get Group Tree',
        methodName: 'Group/getGroupTree',
        method: () => {
          return HTTP.get('Group/grouptree', {params: {id}})
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async updateGroupTree({ commit, dispatch }, grouptree) {
      const results = await requestHandler({
        title: 'updateGroupTree',
        methodName: 'Group/grouptreeupdate',
        method: () => {
          return HTTP.post('Group/grouptreeupdate', grouptree)
        },
        success: result => {
          commit('UPDATE_GROUP_TREE', result.data)
          return result
        }
      })
      return results
    },

    async getGroupOver({ commit, dispatch }, groupID) {
      const results = await requestHandler({
        title: 'getGroupOver',
        methodName: 'Group/getGroupOver',
        method: () => {
          return HTTP.get(`Group/overgroup/${groupID || 'all'}`)
        },
        success: result => {
          commit('GET_GROUP_OVER', result.data)
          return result
        }
      })
      return results
    },

    async createGroupOver({ dispatch }, group) {
      const results = await requestHandler({
        title: 'createGroupOver',
        methodName: 'Group/createGroupOver',
        method: () => {
          return HTTP.post('Group/overgroup', group)
        },
        success: result => result
      })
      return results
    },

    async createGroupMajor({ dispatch }, group) {
      const results = await requestHandler({
        title: 'createGroupMajor',
        methodName: 'Group/createGroupMajor',
        method: () => {
          return HTTP.post('Group/majorgroup', group)
        },
        success: result => result
      })
      return results
    },

    async createGroupItem({ dispatch }, group) {
      const results = await requestHandler({
        title: 'createGroupItem',
        methodName: 'Group/createGroupItem',
        method: () => {
          return HTTP.post('Group/itemgroup', group)
        },
        success: result => result
      })
      return results
    },

    async updateGroupOver({ dispatch }, group) {
      const results = await requestHandler({
        title: 'updateGroupOver',
        methodName: 'Group/updateGroupOver',
        method: () => {
          return HTTP.put('Group/overgroup', group)
        },
        success: result => result
      })
      return results
    },

    async updateGroupMajor({ dispatch }, group) {
      const results = await requestHandler({
        title: 'updateGroupMajor',
        methodName: 'Group/updateGroupMajor',
        method: () => {
          return HTTP.put('Group/majorgroup', group)
        },
        success: result => result
      })
      return results
    },

    async updateGroupItem({ dispatch }, group) {
      const results = await requestHandler({
        title: 'updateGroupItem',
        methodName: 'Group/updateGroupItem',
        method: () => {
          return HTTP.put('Group/itemgroup', group)
        },
        success: result => result
      })
      return results
    },

    async removeGroupOver({ commit, dispatch }, { overGroup }) {
      const results = await requestHandler({
        title: 'removeGroupOver',
        methodName: 'Group/removeGroupOver',
        method: () => {
          return HTTP.delete('Group/overgroup', {
            params: {
              Id: overGroup.id
            }
          })
        },
        success: result => result
      })
      return results
    },

    async removeGroupMajor({ commit, dispatch }, { majorGroup, overGroup }) {
      const results = await requestHandler({
        title: 'removeGroupMajor',
        methodName: 'Group/removeGroupMajor',
        method: () => {
          return HTTP.delete('Group/majorgroup', {
            params: {
              Id: majorGroup.id
            }
          })
        },
        success: result => result
      })
      return results
    },

    async removeGroupItem({ commit, dispatch }, { itemGroup, majorGroup }) {
      const results = await requestHandler({
        title: 'removeGroupItem',
        methodName: 'Group/removeGroupItem',
        method: () => {
          return HTTP.delete('Group/itemgroup', {
            params: {
              Id: itemGroup.id
            }
          })
        },
        success: result => result
      })
      return results
    },

    async getOverGroupAll({ commit, dispatch }) {
      const results = await requestHandler({
        title: 'getOverGroupAll',
        methodName: 'getOverGroupAll',
        method: () => {
          return HTTP.get('Group/overgroup/all')
        },
        success: result => result
      })
      return results
    },

    async getOverGroup({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getOverGroup',
        methodName: 'getOverGroup',
        method: () => {
          return HTTP.get('Group/overgroup', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async getMajorGroupAll({ commit, dispatch }) {
      const results = await requestHandler({
        title: 'getMajorGroupAll',
        methodName: 'getMajorGroupAll',
        method: () => {
          return HTTP.get('Group/majorgroup/all')
        },
        success: result => result
      })
      return results
    },

    async getMajorGroup({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getMajorGroup',
        methodName: 'getMajorGroup',
        method: () => {
          return HTTP.get('Group/majorgroup', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async getItemGroupAll({ commit, dispatch }) {
      const results = await requestHandler({
        title: 'getItemGroupAll',
        methodName: 'Group/getItemGroupAll',
        method: () => {
          return HTTP.get('Group/itemgroup/all')
        },
        success: result => {
          commit('SET_ITEM_GROUP_LIST', result.data.itemGroupList)
          return result
        }
      })
      return results
    },
    async getItemGroup({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getItemGroup',
        methodName: 'Group/getItemGroup',
        method: () => {
          return HTTP.get('Group/itemgroup', {
            params: { ...payload }
          })
        },
        success: result => {
          commit('SET_ITEM_GROUP_LIST', result.data.itemGroupList)
          return result
        }
      })
      return results
    },

    async getItemGroupByOverGroupId({ commit, dispatch }, overGroupIds) {
      const results = await requestHandler({
        title: 'getItemGroupByOverGroupId',
        methodName: 'Group/getItemGroupByOverGroupId',
        method: () => {
          return HTTP.post(`Group/itemgroup/all/filter`, { overGroupIds })
        },
        success: result => {
          commit('SET_ITEM_GROUP_LIST', result.data.itemGroupList)
          return result
        }
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.groups = []
      state.allItemGroups = []
      state.allOverGroups = []
      state.itemGroups = []
    },

    GET_GROUP_OVER(state, list) {
      state.allOverGroups = list
    },

    UPDATE_GROUP_TREE(state, list) {
      state.groups = list
    },

    SET_ITEM_GROUP_LIST(state, list) {
      state.allItemGroups = list
    }
  }
}
